import './styles.sass'
import React, { Component } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'

export default class Four04 extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    className: '',
    style: null,
  }

  get className() {
    const { className } = this.props
    return c('dc-404', className)
  }

  render() {
    const { style } = this.props
    const { className } = this
    return (
      <div className={className} style={style} id="404" title="404">
        404
      </div>
    )
  }
}
